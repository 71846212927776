<template>
  <div class="create-popup-container">
    <button
      data-test-id="header-button-create"
      class="btn text-white font-weight-bold"
      @click=";(state.modal.isVisible = true), toggleBodyScroll(true)"
    >
      <i class="ri-apps-2-line"></i>
    </button>
    <CModal
      class="overflow-auto"
      backdrop="static"
      size="xl"
      :visible="state.modal.isVisible"
      :close-on-backdrop="false"
    >
      <div
        class="pt-4 px-4 create-popup-header d-flex align-items-center justify-content-between"
      >
        <HelpCenterButton
          data-test-id="header-button-help-center"
          v-if="state.modal.activeModalTab"
          :content-key="helpCenterKey"
        />
        <div class="create-popup-container-text">Create</div>
        <div
          data-test-id="header-button-close-modal"
          class="d-inline-block text-black p-1 lh-normal cursor-pointer"
          @click="closeModal()"
        >
          <i class="ri-close-line"></i>
        </div>
      </div>

      <div
        v-if="
          !state.modal.activeModalTab || state.modal.activeModalTab === 'all'
        "
        class="px-0 px-md-4 pb-4 text-center"
      >
        <CContainer>
          <div class="create-box-grid" :class="gridClasses">
            <div
              class="create-box-grid"
              :class="{
                'create-box-col-span-2':
                  !isActiveKioskModule && !isActiveAppoinmentModule
              }"
            >
              <div
                class="create-box mr-2 cursor-pointer"
                @click="setActiveTab('teacher_pass_pop_up')"
                data-test-id="header-button-teacher-pass"
              >
                <div class="text-center mb-3">
                  <i class="small ri-door-open-line"></i>
                </div>
                <div class="create-box-text text-center">
                  Teacher Pass<br />
                  <div class="create-box-text-help">(TCH)</div>
                </div>
              </div>
              <div
                v-if="isActiveAppoinmentModule"
                class="create-box cursor-pointer"
                @click="setActiveTab('appointment_pass_pop_up')"
                data-test-id="header-button-appointment-pass"
              >
                <div class="text-center mb-3">
                  <i class="small ri-calendar-check-line"></i>
                </div>
                <div class="create-box-text text-center">
                  Appointment Pass
                  <div class="create-box-text-help">(APT)</div>
                </div>
              </div>
            </div>

            <div class="create-box-grid">
              <div
                data-test-id="header-button-ooo"
                class="create-box cursor-pointer"
                @click="setActiveTab('out_of_office_pop_up')"
              >
                <div class="text-center mb-3">
                  <i class="small ri-suitcase-3-line"></i>
                </div>
                <div class="create-box-text text-center">Out of Office</div>
              </div>
              <div
                v-if="isActiveKioskModule"
                data-test-id="header-button-activate-kiosk"
                class="create-box cursor-pointer"
                @click="goToPage('/kiosk')"
              >
                <div class="text-center mb-3">
                  <i class="small ri-device-line"></i>
                </div>
                <div class="create-box-text text-center">Activate Kiosk</div>
              </div>
            </div>

            <div
              v-if="isVisitorManagementAval"
              class="create-box cursor-pointer"
              :class="{
                'col-span-2': !isActiveKioskModule && !isActiveAppoinmentModule
              }"
              @click="$router.push('/visitor/management/check-ins-and-logs')"
            >
              <div class="text-center mb-3">
                <i class="small ri-user-add-line"></i>
              </div>
              <div class="create-box-text text-center">Visitor Check-in</div>
            </div>
          </div>

          <div class="other-links mb-3 mt-3 justify-content-center text-center">
            Other links
          </div>
          <CRow v-if="userRole === 'admin'" class="create-limits-container">
            <CCol
              data-test-id="header-button-concat-control"
              class="create-box cursor-pointer"
              @click="setActiveTab('a_b_polarity_pop_up')"
            >
              <div class="create-box-icon text-center mb-1">
                <i class="ri-a-b"></i>
              </div>
              <div class="create-box-text text-center">Contact Control</div>
            </CCol>
            <CCol
              data-test-id="header-button-limit-location-max-cap"
              class="create-box cursor-pointer"
              @click="setActiveTab('limit_location_max_cap_pop_up')"
            >
              <div class="text-center mb-1">
                <i class="ri-team-line"></i>
              </div>
              <div class="create-box-text text-center">
                Limit Location Max Cap
              </div>
            </CCol>
            <CCol
              data-test-id="header-button-limit-location-availability"
              class="create-box cursor-pointer"
              @click="setActiveTab('limit_local_availability_pop_up')"
            >
              <div class="text-center mb-1">
                <i class="ri-map-pin-5-line"></i>
              </div>
              <div class="create-box-text text-center">
                Limit Location Availability
              </div>
            </CCol>
            <CCol
              data-test-id="header-button-limit-student-pass"
              class="create-box cursor-pointer"
              @click="setActiveTab('limit_students_passes_pop_up')"
            >
              <div class="text-center mb-1">
                <i class="ri-traffic-light-line"></i>
              </div>
              <div class="create-box-text text-center">Limit Student Pass</div>
            </CCol>
            <CCol
              data-test-id="header-button-limit-active-pass"
              class="create-box cursor-pointer"
              @click="setActiveTab('limit_acive_passes_pop_up')"
            >
              <div class="text-center mb-1">
                <i class="ri-door-closed-line"></i>
              </div>
              <div class="create-box-text text-center">
                Limit Active Pass
              </div> </CCol
            ><CCol
              data-test-id="header-button-pass-blocking"
              class="create-box cursor-pointer"
              @click="setActiveTab('pass_blocking_pop_up')"
            >
              <div class="text-center mb-1">
                <i class="ri-lock-line"></i>
              </div>
              <div class="create-box-text text-center">Pass Blocking</div>
            </CCol>
          </CRow>
          <CRow v-if="userRole === 'admin'" class="quick-links-container">
            <CCol
              class="create-custom-box cursor-pointer"
              data-test-id="header-button-polarity-report"
            >
              <router-link to="/limits/ab-polarity">
                <CCol>
                  <div class="create-custom-box-text">
                    <i class="ri-group-line"></i>Polarity Report
                  </div>
                </CCol>
              </router-link>
            </CCol>

            <CCol
              class="create-custom-box cursor-pointer"
              data-test-id="header-button-pass-history"
            >
              <router-link to="/history">
                <CCol>
                  <div class="create-custom-box-text">
                    <i class="ri-history-line"></i>
                    Pass History
                  </div>
                </CCol>
              </router-link>
            </CCol>
          </CRow>
          <CRow
            v-if="userRole === 'teacher' || userRole === 'staff'"
            class="quick-links-container-teacher-staff"
          >
            <CCol
              class="create-custom-box cursor-pointer"
              data-test-id="header-button-pass-history"
            >
              <router-link to="/history">
                <CCol>
                  <div class="create-custom-box-text">
                    <i class="ri-history-line"></i>
                    Pass History
                  </div>
                </CCol>
              </router-link>
            </CCol>
          </CRow>
          <CRow v-if="userRole === 'admin'" class="quick-links-container">
            <CCol
              class="create-custom-box cursor-pointer"
              data-test-id="header-button-summary-report"
            >
              <router-link to="/reports/summary-reports">
                <CCol>
                  <div class="create-custom-box-text">
                    <i class="ri-book-read-line"></i>
                    Summary Report
                  </div>
                </CCol>
              </router-link>
            </CCol>
            <CCol
              class="create-custom-box cursor-pointer"
              data-test-id="header-button-contact-tracing"
            >
              <router-link to="/reports/meet-up-report">
                <CCol>
                  <div class="create-custom-box-text">
                    <i class="ri-parent-line"></i>
                    Meet-up Report
                  </div>
                </CCol>
              </router-link>
            </CCol>
            <CCol
              class="create-custom-box cursor-pointer"
              data-test-id="header-button-help-desk"
            >
              <router-link to="/help-desk">
                <CCol>
                  <div class="create-custom-box-text">
                    <i class="ri-parent-line"></i>
                    Help Desk
                  </div>
                </CCol>
              </router-link>
            </CCol>
          </CRow>
        </CContainer>
      </div>

      <div v-if="state.modal.activeModalTab" class="create-modals">
        <div
          v-if="state.modal.activeModalTab === 'teacher_pass_pop_up'"
          class="text-center px-5"
        >
          <h2 class="mb-2">Teacher Pass Pop-up</h2>
          <CreateTeacherPassForm @close-modal="closeModal()" />
        </div>
        <div
          v-if="
            isActiveAppoinmentModule &&
            state.modal.activeModalTab === 'appointment_pass_pop_up'
          "
          class="text-center px-5"
        >
          <h2 class="mb-4">Appointments Pop-up</h2>
          <CreateAppoinmentsPassForm
            form-index="0"
            :periods="periods"
            :is-header-create-button="true"
            @close-modal="closeModal()"
          />
        </div>
        <div
          v-if="state.modal.activeModalTab === 'out_of_office_pop_up'"
          class="text-center px-5"
        >
          <h2 class="mb-4">Out Of Office Pop-up</h2>
          <OutOfOfficeForm
            :is-header-create-button="true"
            @cancel="closeModal()"
          />
        </div>
        <div v-if="userRole === 'admin'">
          <div
            v-if="state.modal.activeModalTab === 'a_b_polarity_pop_up'"
            class="text-center px-5"
          >
            <h2 class="mb-4">Contact Control (A/B Polarity) Pop-up</h2>
            <CreateContactControlForm
              :is-header-create-button="true"
              @close-modal="closeModal()"
            />
          </div>
          <div
            v-if="
              state.modal.activeModalTab === 'limit_location_max_cap_pop_up'
            "
            class="text-center px-5"
          >
            <h2 class="mb-4">Limit Location Max Cap Pop-up</h2>
            <CreateLimitLocationMaxCapForm
              :is-dashboard-view="true"
              :is-header-create-button="true"
              @close-modal="closeModal()"
            />
          </div>
          <div
            v-if="
              state.modal.activeModalTab === 'limit_local_availability_pop_up'
            "
            class="text-center px-5"
          >
            <h2 class="mb-4">Limit Location Availability Pop-up</h2>
            <CreateLimitLocationAvailabilityForm @close-modal="closeModal()" />
          </div>
          <div
            v-if="state.modal.activeModalTab === 'limit_students_passes_pop_up'"
            class="text-center px-5"
          >
            <h2 class="mb-4">Limit Student(s) Passes Pop-up</h2>
            <CreateLimitStudentPassForm
              :is-in-view="false"
              @close-modal="closeModal()"
            />
          </div>
          <div
            v-if="state.modal.activeModalTab === 'limit_acive_passes_pop_up'"
            class="px-5"
          >
            <h2 class="mb-4">Limit Active Passes Pop-up</h2>
            <LimitActivePassForm @close-modal="closeModal()" />
          </div>
          <div
            v-if="state.modal.activeModalTab === 'pass_blocking_pop_up'"
            class="text-center px-5"
          >
            <h2 class="mb-4">Pass blocking Pop-up</h2>
            <CreatePassBlockingForm
              :is-header-create-button="true"
              @close-modal="closeModal()"
            />
          </div>
        </div>
      </div>

      <template #footer-wrapper>
        <div></div>
      </template>
    </CModal>
  </div>
</template>

<script>
import { reactive, computed, watch, onMounted } from "vue"
import { useStore } from "vuex"
import { useRoute } from "vue-router"
import router from "@/router"
import { shouldVisitorWebAppBeHidden } from "@/helpers/featureFlags"
import CreateTeacherPassForm from "@/v3components/Forms/CreateTeacherPassForm"
import CreateAppoinmentsPassForm from "@/v3components/Forms/CreateAppointmentPassMultipleForm"
import OutOfOfficeForm from "@/v3components/Forms/OutOfOfficeForm"
import CreateContactControlForm from "@/v3components/Forms/CreateContactControlForm"
import CreateLimitLocationMaxCapForm from "@/v3components/Forms/CreateLimitLocationMaxCapForm"
import CreateLimitLocationAvailabilityForm from "@/v3components/Forms/CreateLimitLocationAvailabilityForm"
import CreateLimitStudentPassForm from "@/v3components/Forms/CreateLimitStudentPassForm"
import CreatePassBlockingForm from "@/v3components/Forms/CreatePassBlockingForm"
import LimitActivePassForm from "@/v3components/Forms/LimitActivePassForm"
import HelpCenterButton from "@/v3components/HelpCenterButton"

export default {
  name: "TheHeaderCreateButton",
  components: {
    CreateTeacherPassForm,
    CreateAppoinmentsPassForm,
    OutOfOfficeForm,
    CreateContactControlForm,
    CreateLimitLocationMaxCapForm,
    CreateLimitLocationAvailabilityForm,
    CreateLimitStudentPassForm,
    CreatePassBlockingForm,
    LimitActivePassForm,
    HelpCenterButton
  },
  props: {
    userRole: {
      type: String,
      required: true
    }
  },
  setup() {
    const store = useStore()
    const route = useRoute()

    const state = reactive({
      modal: {
        isVisible: false,
        activeModalTab: "all",
        size: "lg"
      }
    })

    const gridClasses = computed(() => {
      // If Visitor Management is not available
      if (!isVisitorManagementAval.value) {
        // If only the Kiosk Module is active
        if (isActiveKioskModule.value && !isActiveAppoinmentModule.value) {
          return "create-box-grid-cols-2"
        }

        // If only the Appointment module is active
        if (!isActiveKioskModule.value && isActiveAppoinmentModule.value) {
          return "create-box-grid-cols-2"
        }

        // Return 2 columns if both Kiosk and Appointment modules are active, else 1 column
        return isActiveKioskModule.value && isActiveAppoinmentModule.value
          ? "create-box-grid-cols-2"
          : "create-box-grid-cols-1"
      }

      // Return 3 columns if Visitor Management is available and Kiosk is not active
      if (isActiveAppoinmentModule.value && !isActiveKioskModule.value) {
        return "create-box-grid-cols-3"
      }

      // If Visitor Management is available but Kiosk Module is not active
      if (!isActiveKioskModule.value) {
        return "create-box-grid-cols-2"
      }

      // Default to 3 columns if Visitor Management and Kiosk Module are active
      return "create-box-grid-cols-3"
    })

    const periods = computed(() => {
      return store.getters["periods/formListStudents"]
    })

    const isActiveAppoinmentModule = computed(() => {
      return store.getters["schools/isActiveAppoinmentModule"]
    })

    const isActiveKioskModule = computed(() => {
      return store.getters["schools/isActiveKioskModule"]
    })

    const isVisitorModuleActive = computed(() => {
      return store.getters["schools/isVisitorModuleActive"]
    })

    const helpCenterKey = computed(() => {
      return state.modal.activeModalTab === "all"
        ? "user_menu_create_button"
        : state.modal.activeModalTab
    })

    const isVisitorManagementAval = computed(() => {
      const keepVisitorHidden = shouldVisitorWebAppBeHidden()
      return (
        !keepVisitorHidden &&
        isVisitorModuleActive.value &&
        store.getters["visitorSettings/hasVisitorCreateMenuAccess"]
      )
    })

    watch(
      () => route.path,
      () => {
        closeModal()
      }
    )

    onMounted(() => {
      store.dispatch("periods/getPeriods")
      store.dispatch("rooms/getUserAssignRooms")
    })

    const setActiveTab = (tab, size = null) => {
      if (route.path.includes(tab)) {
        closeModal()
      } else {
        state.modal.activeModalTab = tab
        state.modal.size = size
      }
    }

    const closeModal = () => {
      state.modal.isVisible = false
      setTimeout(() => {
        state.modal.activeModalTab = "all"
        state.modal.size = "xl"
      }, 500)
      toggleBodyScroll(false)
    }

    const toggleBodyScroll = (isVisible) => {
      const body = document.getElementsByTagName("body")[0]
      if (isVisible) {
        body.classList.add("overflow-hidden")
      } else {
        body.classList.remove("overflow-hidden")
      }
    }

    const goToPage = (page) => {
      if (route.path !== page) {
        router.push(page)
      }
      closeModal()
    }

    return {
      state,
      periods,
      gridClasses,
      isActiveAppoinmentModule,
      isActiveKioskModule,
      helpCenterKey,
      setActiveTab,
      isVisitorManagementAval,
      closeModal,
      toggleBodyScroll,
      goToPage
    }
  }
}
</script>
